import jQuery from 'jquery';
window.$ = jQuery;

jQuery(document).ready(function ($) {
    const body = $('body'),
        window = $(window);
    var backTop = $('#back-to-top');

    body.on('click', '.footer-column__title:not(.footer-column__title-social)', function (e) {
        if ($(this).hasClass('visible')) {
            $(this).removeClass('visible').next().slideUp('visible');
        } else {
            $('.footer-column__title:not(.footer-column__title-social)').removeClass('visible').next().slideUp('visible');
            $(this).addClass('visible').next().slideDown('visible');
        }
    });
    body.on('click', '.language-opener', function (e) {
        $(this).toggleClass('visible').parent().toggleClass('visible');
    });

    var cookie_marketing = checkCookieValue('CookieConsent', 'targeting')
    // SI cookie Marketing non activé, alors ....

    if ( !cookie_marketing ) {
        $('iframe').remove();
        $('.epp-message-iframe').css('display','flex');
    }

    backTop.click(function(){
        $('html,body').animate({
            scrollTop: 0
        }, 600);
    });

    jQuery(document).on('scroll', function(){
        if($(this).scrollTop() > 300){
            backTop.addClass('visible');
        }else{
            backTop.removeClass('visible');
        }
    });

});

// Fonction pour vérifier si un cookie contient une certaine valeur
function checkCookieValue(cookieName, targetValue) {
    // Split the cookies by semicolon and trim any leading/trailing whitespace
    var cookies = document.cookie.split(';').map(cookie => cookie.trim());

    // Iterate through the cookies to find the one with the specified name
    for (var i = 0; i < cookies.length; i++) {
        var cookie = decodeURIComponent(cookies[i]);

        // Check if the cookie starts with the desired name
        if (cookie.startsWith(cookieName + '=')) {
            // Extract the cookie value
            var cookieValue = cookie.substring(cookieName.length + 1);

            // Check if the cookie value matches the target value
            if (cookieValue === targetValue) {
                return true; // Cookie contains the target value
            } else if (cookieValue.includes(targetValue)) {
                return true; // Cookie contains the target value
            }
        }
    }

    // If the cookie with the specified name or value is not found, return false
    return false;
}
